@import "../../../style-config/variable.scss",
    "../../../style-config/mixins.scss";

.section {
    padding: 50px 0;
    position: relative;
    display: flex;
    justify-content: center;
    @include small-screen {
        padding: 100px 0;
    }
    &.dark {
        background-color: $black;
    }
    &.light {
        background-color: $black-light;
    }
    .content {
        width: 100%;
        padding: 0 20px;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        @include small-screen {
            width: 1200px;
        }
        .section-title {
            width: 100%;
            text-align: center;
            color: $white;
            max-width: 500px;
        }
    }
}
