@import "../../style-config/mixins.scss", "../../style-config/variable.scss";

.skills-content-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    @include small-screen {
        flex-direction: row;
    }
    .left-col {
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include small-screen {
            width: 40%;
        }
        img {
            width: 100%;
            max-width: 453px;
            display: block;
        }
    }
    .right-col {
        width: 100%;
        color: $white;
        text-align: center;
        margin-top: 25px;
        @include small-screen {
            margin-left: 70px;
            width: calc(60% - 70px);
            text-align: left;
            margin-top: 0;
        }
        h2 {
            margin-bottom: 10px;
            @include small-screen {
                margin-bottom: 30px;
            }
        }
        p {
            @include typography(14px, 400, 20px);
            margin-bottom: 15px;
            @include small-screen {
                @include typography(18px, 400, 24px);
            }
        }
        .call-to-action {
            margin: 0 auto;
            @include small-screen {
                margin: 0;
            }
        }
    }
}
