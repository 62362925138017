@import "../../../style-config/variable.scss";
.arrow-icon {
    width: 21px;
    height: 21px;
    background: $yellow;
    border-radius: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    svg {
        transform: rotate(-45deg);
    }
}
