@import "../../../style-config/mixins.scss",
    "../../../style-config/variable.scss";

.query-form {
    width: 100%;
    @include small-screen {
        width: calc(100% - 375px);
        margin-left: 20px;
    }
    form {
        display: flex;
        flex-flow: row wrap;
        margin-top: 25px;
        @include small-screen {
            margin-top: -10px;
        }
    }
    .input-field {
        width: 100%;
        margin-bottom: 10px;
        @include small-screen {
            width: calc(50% - 20px);
            margin: 10px;
        }
        &.full-width {
            width: 100%;
        }
        input {
            width: 100%;
            height: 57px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 6px;
            background-color: transparent;
            outline: 0;
            padding: 10px 20px;
            color: $white;
            @include typography(18px, 400, 24px);
            &:focus {
                border-color: $white;
            }
        }
        .textarea {
            width: 100%;
            height: 120px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 6px;
            background-color: transparent;
            outline: 0;
            padding: 10px 20px;
            color: $white;
            resize: none;
            @include typography(18px, 400, 24px);
            &:focus {
                border-color: $white;
            }
        }
        .error {
            color: $white;
            @include typography(12px, 400, 24px);
        }
    }
    button {
        outline: 0;
        border: 0;
        width: 90px;
        height: 47px;
        background: $white;
        border-radius: 6px;
        @include typography(18px, 400, 24px);
        margin-top: 10px;
        cursor: pointer;
        color: $black;
        @include small-screen {
            margin-left: 10px;
        }
    }
}
