@import "../../../style-config/variable.scss",
    "../../../style-config/mixins.scss";

.call-to-action {
    height: 47px;
    width: fit-content;
    padding: 0 15px;
    background: $white;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black-light;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    @include typography(18px, 400, 1);
    .text,
    .icon,
    .arrow-icon {
        position: relative;
        z-index: 1;
        transition: all ease 500ms;
    }
    .icon {
        width: 21px;
        height: 21px;
        background: $yellow;
        border-radius: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        font-size: 12px;
    }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $yellow;
        z-index: 0;
        transform: translateX(-100%);
        transition: all 300ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &:hover {
        &:before {
            transform: translateX(0);
        }
        .arrow-icon,
        .icon {
            background-color: $white;
        }
    }
}
