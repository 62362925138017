@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import "./style-config/variable.scss", "./style-config/mixins.scss";
* {
    margin: 0;
    padding: 0;
    font-family: $base-font-family;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    @include typography(14px, 400, 20px);
    @include small-screen {
        @include typography(18px, 400, 24px);
    }
}

h2 {
    @include typography(32px, 700, 1.2);
    @include small-screen {
        @include typography(42px, 700, 1.2);
    }
}

h3 {
    @include typography(14px, 500, 1.2);
    @include small-screen {
        @include typography(26px, 500, 1.2);
    }
}

h4 {
    @include typography(16px, 500, 1.2);
    @include small-screen {
        @include typography(22px, 500, 1.2);
    }
}
