@import "../../../style-config/mixins.scss",
    "../../../style-config/variable.scss";
.blog-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 20px;
    @include small-screen {
        height: 538px;
        width: calc(100% / 3);
        margin-right: 20px;
        margin-bottom: 0;
    }
    .image-section {
        width: 100%;
        height: 200px;
        overflow: hidden;
        @include transition-ease;
        @include small-screen {
            height: 250px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            display: block;
        }
    }
    .content-section {
        display: flex;
        flex-direction: column;
        padding: 30px;
        padding-bottom: 75px;
        position: relative;
        flex-grow: 1;
        background: #ffffff;
        @include transition-ease;
        @include small-screen {
            padding-bottom: 55px;
        }

        .info-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .user-name,
            .posted-date {
                @include typography(18px, 500, 20px);
            }
        }
        h3 {
            margin-bottom: 20px;
            @include ellipsis;
        }
        p {
            color: $black-light;
            @include ellipsis;
        }
        .readmore-cta {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 30px;
            left: 30px;
            cursor: pointer;
            .text {
                @include typography(18px, 500, 20px);
            }
        }
    }

    &:hover {
        @include small-screen {
            .image-section {
                transform: scale(1.2);
            }
            .content-section {
                background-color: $black-light;
                color: $white;
                p {
                    color: $white;
                }
            }
        }
    }
}
